<template>
  <v-card
    height="100%"
    elevation="4"
    class="model-card"
    :class="[isSelected ? 'selected-option' : 'normal-border']"
    @click.native="$emit('update:selected', salesType)"
  >
    <v-container height="50%" class="pa-0" fluid>
      <v-card-text>
        <v-flex>
          <h3 class="body-4 model" :class="[isSelected ? 'text-selected' : '']">{{ text }}</h3>
        </v-flex>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    salesType: {
      type: String,
      required: true
    },
    selected: {
      type: String,
      required: true
    }
  },
  computed: {
    isSelected() {
      return Boolean(this.salesType === this.selected)
    }
  }
}
</script>

<style lang="scss">
$tablet-size: 960px;
.model-card {
  cursor: pointer;
  border-radius: 50px;
  border-radius: 50px;
  &.selected-option {
    background-color: #334a58 !important;
  }
  &.normal-border {
    margin: 2px;
  }
  .card-icon-background {
    background-color: #f1f3f5;
  }
  .text-selected {
    color: #ffffff;
  }
  .model {
    font-size: 18px !important;
    font-weight: bold;
    text-align: center;
  }
}
</style>
